import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Youtube from "../components/Video/Youtube"
import HorizontalScroll from "../components/horizontalScroll"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import before from "../images/before-facelift.webp"
import after from "../images/after-facelift.webp"
// import QAAccordion from "../components/accordion"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  bodyHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 600,
    color: "#202020",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  figText: {
    fontSize: "1.1rem",
    textAlign: "left",
    fontStyle: "italic",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.up("md")]: {
      paddingTop: 20,
    },
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "#202020",
    maxWidth: 600,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 200,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "#575757",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  videoText: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  scrollTitle: {
    paddingTop: 140,
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    ["@media (max-width:600px)"]: {
      paddingTop: 80,
    },
  },
  scrollText: {
    paddingTop: 30,
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    fontSize: "1.6rem",
    ["@media (max-width:600px)"]: {
      fontSize: "0.9rem",
    },
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: 260,
    },
  },
  imageContainer: {
    paddingTop: 80,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  imageContainerBeforeAfter: {
    [theme.breakpoints.down("md")]: {},
  },
  videoContainer: {
    maxHeight: 400,
  },
  sectionContainer: {
    paddingTop: 80,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  scrollSection: {
    paddingTop: -20,
    paddingBottom: 60,
    marginBottom: 140,
  },
  beforeAndAfter: {
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  beforeAfter: {
    maxWidth: 290,
    // marginBottom: 100,
    // [theme.breakpoints.down("xl")]: {
    //   maxWidth: 330,
    // },
  },
  sectionContainerExplanation: {
    paddingTop: 60,
    paddingBottom: 30,
    background: "white",
    // background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  faceList: {
    fontSize: "1.1rem",
    color: "#202020",
    maxWidth: 600,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textAlign: "left",
  },
  questions: {
    fontSize: "0.9rem",
    textAlign: "left",
    color: "#929B91",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 10,
  },
  helpOutline: {
    color: "#929B91",
  },
  faq: {
    color: "#929B91",
    fontSize: "1.1rem",
    fonWeight: "bold",
  },
  qaList: {
    paddingBottom: 10,
  },
  layers: {
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
      width: 340,
    },
  },
  outroContainer: {
    // background: "#BABFB9",
    background: "white",
  },
}))

const DeepPlane = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid
  const scrollImages = [
    data.itLateral,
    data.it34,
    data.mkLateral,
    // data.msAngelineLateral,
    // data.msAngelineFront,
    // data.msAngeline34,
    data.jaqLateral,
  ]
  const faceExplained = [
    t("deepPlane.faceExplained.1"),
    t("deepPlane.faceExplained.2"),
    t("deepPlane.faceExplained.3"),
    t("deepPlane.faceExplained.4"),
  ]
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "deepPlane.SEOtitle" })}
        description={intl.formatMessage({ id: "deepPlane.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <section>
          <BackgroundImage
            fluid={banner}
            className={classes.backgroundImg}
            backgroundColor={`#333`}
          >
            <Grid container spacing={0} className={classes.bannerGrid}>
              <Grid item xs={12} sm={4} className={classes.bannerLeft}>
                <Container>
                  <LineTitle
                    titleContainer={classes.titleContainer}
                    header={t("deepPlane.title")}
                    titleStyle={classes.tooLongtitle}
                    lightDecoLine
                    subHeaderStyle={classes.tooLongSubheader}
                  />
                </Container>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </BackgroundImage>
          <Grid container spacing={12} className={classes.sectionContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.introHeader}
              >
                {t("deepPlane.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("deepPlane.introText")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              align="center"
              className={classes.imageContainer}
            >
              {/* <Img
                className={classes.imageContainerBeforeAfter}
                fluid={data.msAngeline34.childImageSharp.fluid}
                alt="before and after deep plane facelift"
              />
              <p className={classes.figText}>
                Pre and Post-Facelift: Dr. Callum Faris's Artistry in Facial
                Rejuvenation
              </p> */}
              <div style={{ maxWidth: "400px", height: "100%", flexGrow: 1 }}>
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      // src={data.beforeFacelift.childImageSharp.fluid}
                      src={before}
                      // srcSet="..."
                      alt="Image one"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      // src={data.afterFacelift.childImageSharp.fluid}
                      src={after}
                      // srcSet="..."
                      alt="Image two"
                    />
                  }
                />
                <p className={classes.figText}>
                  Pre and Post-Facelift: Dr. Callum Faris's Artistry in Facial
                  Rejuvenation
                </p>
              </div>
            </Grid>
          </Grid>
          {/* <Grid container spacing={12} className={classes.sectionContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.introHeader}
              >
                {t("deepPlane.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("deepPlane.introText")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.front.childImageSharp.fluid}
                alt="Before and after cheek"
                className={classes.beforeAfter}
              ></Img>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.side.childImageSharp.fluid}
                alt="Before and after facelift"
                className={classes.beforeAfter}
              ></Img>
            </Grid>
          </Grid> */}

          <Grid container spacing={12} className={classes.sectionContainer}>
            <Grid
              item
              xs={12}
              sm={6}
              align="center"
              className={classes.videoContainer}
            >
              <Youtube
                videoUrl="https://www.youtube.com/watch?v=Pjsr7wclc9Q"
                imgFluid={data.explained.childImageSharp.fluid}
              ></Youtube>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.videoText}
              >
                {t("deepPlane.videoText")}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={12} className={classes.beforeAndAfter}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.scrollTitle}
              >
                Natural Results
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} align="center"></Grid>
          </Grid>

          <HorizontalScroll images={scrollImages} />

          <Grid container spacing={12} className={classes.scrollSection}>
            <Grid item xs={12} sm={6} align="center">
              {/* <QAAccordion></QAAccordion> */}
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.scrollText}
              >
                Scroll for more <br />
                before and after >>
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            <div className={classes.qaList}>
              <HelpOutline fontSize="large" className={classes.helpOutline} />
              <p className={classes.faq}>{t("deepPlane.qa")}</p>
            </div>
            <p className={classes.questions}>{t("deepPlane.questions")}</p>
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid> */}
        </section>
        <section>
          <Grid
            container
            spacing={12}
            direction={smallScreen ? "column-reverse" : "row"}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h2"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.explainedTitle")}
              </Typography>
              <p className={classes.bodyText2}>{t("deepPlane.introExplain")}</p>
              <ol className={classes.faceList}>
                {faceExplained.map((parts) => (
                  <li>{parts}</li>
                ))}
              </ol>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              <div className={classes.qaList}>
                {/* <HelpOutline fontSize="large" className={classes.helpOutline} /> */}
                <p className={classes.faq}>{t("deepPlane.qa")}</p>
              </div>
              <p className={classes.questions}>{t("deepPlane.questions")}</p>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <p className={classes.bodyText2}>
                {t("deepPlane.faceStructures")}
              </p>
              <p className={classes.bodyText2}>{t("deepPlane.layers")}</p>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <Img
                fluid={data.layers.childImageSharp.fluid}
                alt="Face layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.fourthLayerTitle")}
              </Typography>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerFirst")}
              </p>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.skinFourthLayer.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
              <Img
                fluid={data.skinLayer.childImageSharp.fluid}
                alt="Skin layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid
              item
              xs={12}
              sm={8}
              align="center"
              className={classes.imageContainer}
            >
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerThird")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.faceLigament.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
              {/* <Img
              fluid={data.faceLigamentTwo.childImageSharp.fluid}
              alt="Skin layers"
              className={classes.layers}
            ></Img> */}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.whatLayerTitle")}
              </Typography>
              <p className={classes.bodyText2}>
                {t("deepPlane.whatLayerFirst")}
              </p>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.levelsDeepPlane.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid
              item
              xs={12}
              sm={8}
              align="center"
              className={classes.imageContainer}
            >
              <p className={classes.bodyText2}>
                {t("deepPlane.whatLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              <Img
                fluid={data.results.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid container spacing={12} className={classes.outroContainer}>
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.outroTitle")}
              </Typography>
              <p className={classes.bodyText2}>{t("deepPlane.outroFirst")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.imageContainer}
            >
              {/* <Img
              fluid={data.results.childImageSharp.fluid}
              alt="Face fourth layers"
              className={classes.layers}
            ></Img> */}
            </Grid>
          </Grid>
        </section>
      </Container>
    </Layout>
  )
}

export default injectIntl(DeepPlane)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "HeroBannerDeepPlane3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    afterFacelift: file(relativePath: { eq: "after-facelift.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeFacelift: file(relativePath: { eq: "before-facelift.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngeline34: file(
      relativePath: { eq: "before-after-ms-angeline-34.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngelineLateral: file(relativePath: { eq: "ms-angeline-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngelineFront: file(relativePath: { eq: "ms-angeline-front.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mkLateral: file(relativePath: { eq: "mk-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    itLateral: file(relativePath: { eq: "it-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    it34: file(relativePath: { eq: "it-34.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jaqLateral: file(relativePath: { eq: "jacqueline-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "front-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side: file(relativePath: { eq: "side-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    layers: file(relativePath: { eq: "layers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skinFourthLayer: file(relativePath: { eq: "skin-fourth-layer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skinLayer: file(relativePath: { eq: "skin-layer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceLigament: file(relativePath: { eq: "face-ligament.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceLigamentTwo: file(relativePath: { eq: "face-ligament-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    levelsDeepPlane: file(relativePath: { eq: "levels-deep-plane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    results: file(relativePath: { eq: "operation-results-deep-facelift.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explained: file(relativePath: { eq: "deep-plane-facelift-explained.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
